import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {GET_COURT_CASE_REMINDER, GET_COURT_CASE_PENDING_REMINDER, GET_PENDING_LIAISON_REPORTS} from './types';
import {mainLoadingTrue, mainLoadingFalse} from '../User/action'
import { toast } from 'react-toastify';

export const getCourtCaseReminder_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCOURTCASEREM, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCourtCaseReminderSuccess(data))
            }
            else{
                dispatch(getCourtCaseReminderSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCourtCaseReminderSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCourtCaseReminderSuccess = (data) => {
    return {
        type : GET_COURT_CASE_REMINDER,
        payload : data
    }
}


export const getCourtCasePendingReminder_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETCOURTCASEPENDINGREM, data).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getCourtCasePendingReminderSuccess(data))
            }
            else{
                dispatch(getCourtCasePendingReminderSuccess([]))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getCourtCasePendingReminderSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getCourtCasePendingReminderSuccess = (data) => {
    return {
        type : GET_COURT_CASE_PENDING_REMINDER,
        payload : data
    }
}

export const getPendingLiaisonReports_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.PENDINGLIAISONREPORT, data).then(response => {
            const {data}  = response;
            if(!data.error){
                dispatch(getPendingLiaisonReportsSuccess(data))
            }
            else{
                dispatch(getPendingLiaisonReportsSuccess([]))
            }
            console.log("main_loader ***")
            dispatch(mainLoadingFalse());
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getPendingLiaisonReportsSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}

export const getPendingLiaisonReports_action1 = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.PENDINGLIAISONREPORT_1, data).then(response => {
            const {data}  = response;
            if(!data.error){
                dispatch(getPendingLiaisonReportsSuccess(data))
            }
            else{
                dispatch(getPendingLiaisonReportsSuccess([]))
            }
            console.log("main_loader ***")
            dispatch(mainLoadingFalse());
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getPendingLiaisonReportsSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}


export const getPendingLiaisonReportsSuccess = (data) => {
    return {
        type : GET_PENDING_LIAISON_REPORTS,
        payload : data
    }
}