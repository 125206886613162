export const GET_COURT_CASE_REMINDER = 'GET_COURT_CASE_REMINDER';
export const GET_COURT_CASE_PENDING_REMINDER = 'GET_COURT_CASE_PENDING_REMINDER';
export const GET_PENDING_LIAISON_REPORTS = 'GET_PENDING_LIAISON_REPORTS';


// export const GET_PROJECT_NAMES = 'GET_PROJECT_NAMES';
// export const GET_TASK_DELAYS = 'GET_TASK_DELAYS';
// export const GET_PROFITABLE_REPORT_COUNT = 'GET_PROFITABLE_REPORT_COUNT';
// export const GET_PENDING_TASK = 'GET_PENDING_TASK';
// export const SUCCESS_STATUS = 'SUCCESS_STATUS';
// export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
// export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
// export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';



